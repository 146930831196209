// src/pages/Kilometerliste.js
import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, MenuItem, Dialog,
    DialogActions, DialogContent, DialogTitle, IconButton, TableSortLabel, Grid2
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import '../assets/styles/Main.css';
import { PermissionsContext } from '../context/PermissionsContext'; // Importiere den PermissionsContext

// Konstanten für Monate und Jahre
const months = [
    { value: '01', label: 'Januar' },
    { value: '02', label: 'Februar' },
    { value: '03', label: 'März' },
    { value: '04', label: 'April' },
    { value: '05', label: 'Mai' },
    { value: '06', label: 'Juni' },
    { value: '07', label: 'Juli' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'Oktober' },
    { value: '11', label: 'November' },
    { value: '12', label: 'Dezember' },
];

const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() - index);

const Kilometerliste = ({ darkMode }) => {
    const permissions = useContext(PermissionsContext); // Zugriff auf globale Berechtigungen

    const [entries, setEntries] = useState([]);
    const [newEntry, setNewEntry] = useState({
        date: '',
        funkcode: '',
        km_stand: '',
        profiltiefe_vl: '',
        profiltiefe_vr: '',
        profiltiefe_hl: '',
        profiltiefe_hr: '',
        getankte_liter: ''
    });
    const [funkcodes, setFunkcodes] = useState([]);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFunkcode, setSelectedFunkcode] = useState('');
    const [currentEntry, setCurrentEntry] = useState(null);
    const [openEditModal, setOpenEditModal] = useState(false);
    const currentYear = new Date().getFullYear();
    const lastMonth = ('0' + new Date().getMonth()).slice(-2);

    const [selectedYear, setSelectedYear] = useState(currentYear.toString());
    const [selectedMonth, setSelectedMonth] = useState(lastMonth);
    const [sortConfig, setSortConfig] = useState({ key: 'datum', direction: 'asc' });

    // Hilfsfunktion für Auth-Header
    const getAuthHeaders = () => {
        const token = localStorage.getItem('authToken');
        return { Authorization: `Bearer ${token}` };
    };

    useEffect(() => {
        fetchFunkcodes();
        if (permissions.can_read) {
            fetchEntries();
        }
    }, [permissions.can_read]);

    // Fuhrpark-Funkcodes abrufen
    const fetchFunkcodes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/fuhrpark`, {
                headers: getAuthHeaders()
            });
            setFunkcodes(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Funkcodes:', error);
            alert('Fehler beim Abrufen der Funkcodes');
        }
    };

    // Kilometerstand-Einträge abrufen
    const fetchEntries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/kilometerliste`, {
                headers: getAuthHeaders()
            });
            setEntries(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Kilometerstand-Einträge:', error);
            alert('Fehler beim Abrufen der Kilometerstand-Einträge');
        }
    };

    // Sortierfunktion
    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction:
                prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    // Memoisierte sortierte Daten
    const sortedData = useMemo(() => {
        const sortableItems = [...entries];
        return sortableItems.sort((a, b) => {
            let valueA = a[sortConfig.key];
            let valueB = b[sortConfig.key];

            if (sortConfig.key === 'km_stand') {
                valueA = parseFloat(valueA);
                valueB = parseFloat(valueB);
            } else if (sortConfig.key === 'datum') {
                valueA = new Date(valueA);
                valueB = new Date(valueB);
            }

            if (valueA < valueB) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [entries, sortConfig]);

    // Memoisierte gefilterte Daten
    const filteredData = useMemo(() => {
        return sortedData.filter((entry) => {
            const entryDate = new Date(entry.datum);
            const entryYear = entryDate.getFullYear();
            const entryMonth = ('0' + (entryDate.getMonth() + 1)).slice(-2);

            const matchesSearch =
                entry.funkcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (entry.kennzeichen && entry.kennzeichen.toLowerCase().includes(searchTerm.toLowerCase()));

            const matchesMonth = selectedMonth ? entryMonth === selectedMonth : true;
            const matchesYear = selectedYear ? parseInt(selectedYear, 10) === entryYear : true;
            const matchesFunkcode = selectedFunkcode ? entry.funkcode === selectedFunkcode : true;

            return matchesSearch && matchesMonth && matchesYear && matchesFunkcode;
        });
    }, [sortedData, searchTerm, selectedMonth, selectedYear, selectedFunkcode]);

    // Neuer Eintrag erstellen
    const handleCreateEntry = async () => {
        // Prüfen, ob alle notwendigen Felder ausgefüllt sind
        if (!newEntry.date || !newEntry.funkcode || !newEntry.km_stand) {
            return alert('Bitte füllen Sie alle erforderlichen Felder aus');
        }

        try {
            const payload = {
                date: newEntry.date, // Korrigiert: "datum" statt "date" für Konsistenz mit API
                funkcode: newEntry.funkcode,
                km_stand: parseFloat(newEntry.km_stand), // Umwandeln in float
                profiltiefe_vl: parseFloat(newEntry.profiltiefe_vl) || 0.0,
                profiltiefe_vr: parseFloat(newEntry.profiltiefe_vr) || 0.0,
                profiltiefe_hl: parseFloat(newEntry.profiltiefe_hl) || 0.0,
                profiltiefe_hr: parseFloat(newEntry.profiltiefe_hr) || 0.0,
                getankte_liter: parseFloat(newEntry.getankte_liter) || 0.0
            };
            console.log('Neu', payload);
            // API-Request senden
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/kilometerliste`, payload, {
                headers: getAuthHeaders()
            });

            console.log('Neuer Kilometerstand erfolgreich erstellt:', response.data);
            setNewEntry({
                date: '',
                funkcode: '',
                km_stand: '',
                profiltiefe_vl: '',
                profiltiefe_vr: '',
                profiltiefe_hl: '',
                profiltiefe_hr: '',
                getankte_liter: ''
            }); // Setze die Felder nach dem Erstellen zurück

            setOpen(false); // Schließe das Modal
            fetchEntries(); // Lade die neuen Einträge
        } catch (error) {
            console.error('Fehler beim Erstellen des Eintrags:', error);
            if (error.response && error.response.data && error.response.data.message) {
                alert(`Fehler: ${error.response.data.message}`);
            } else {
                alert('Fehler beim Erstellen des Eintrags');
            }
        }
    };

    // Eintrag bearbeiten und speichern
    const handleSaveEdit = async () => {
        if (!permissions.can_write) {
            alert('Sie haben keine Berechtigung, Kilometerstände zu bearbeiten.');
            return;
        }
        if (!currentEntry.date || !currentEntry.funkcode || !currentEntry.km_stand) {
            return alert('Bitte füllen Sie alle erforderlichen Felder aus');
        }

        try {
            const token = localStorage.getItem('authToken');

            // Füge diese Log-Anweisung hinzu, um zu überprüfen, ob das Datum korrekt im currentEntry gesetzt ist.
            console.log('Current entry before saving:', currentEntry);

            const payload = {
                datum: currentEntry.date,  // Verwende 'date', um das korrekte Datum zu senden
                funkcode: currentEntry.funkcode,
                km_stand: parseFloat(currentEntry.km_stand) || 0.0,
                profiltiefe_vl: parseFloat(currentEntry.profiltiefe_vl) || 0.0,
                profiltiefe_vr: parseFloat(currentEntry.profiltiefe_vr) || 0.0,
                profiltiefe_hl: parseFloat(currentEntry.profiltiefe_hl) || 0.0,
                profiltiefe_hr: parseFloat(currentEntry.profiltiefe_hr) || 0.0,
                getankte_liter: parseFloat(currentEntry.getankte_liter) || 0.0
            };

            // Logge das gesamte Payload-Objekt, um sicherzustellen, dass das Datum korrekt übermittelt wird.
            console.log('Payload to be sent:', payload);

            await axios.put(`${process.env.REACT_APP_API_URL}/kilometerliste/${currentEntry.id}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setOpenEditModal(false);   // Schließe das Bearbeiten-Modal
            setCurrentEntry(null);     // Setze den aktuellen Eintrag zurück
            fetchEntries();            // Lade die aktualisierten Einträge neu
        } catch (error) {
            console.error('Fehler beim Speichern des bearbeiteten Eintrags:', error);
        }
    };


    // Eintrag zum Bearbeiten auswählen
    const handleEdit = (entry) => {
        setCurrentEntry({
            ...entry,
            date: formatDateForInput(entry.datum) // Ändere 'datum' zu 'date', damit es im Modal korrekt angezeigt wird
        });
        setOpenEditModal(true);
    };


    // Eintrag löschen
    const handleDelete = async (id) => {
        if (!permissions.can_write) {
            alert('Sie haben keine Berechtigung, Kilometerstände zu löschen.');
            return;
        }
        if (!id) {
            console.error('Ungültige ID');
            return;
        }

        if (!window.confirm('Möchten Sie diesen Eintrag wirklich löschen?')) {
            return;
        }

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/kilometerliste/${id}`, {
                headers: getAuthHeaders()
            });
            setEntries((prevEntries) => prevEntries.filter((entry) => entry.id !== id));
        } catch (error) {
            console.error('Es gab einen Fehler beim Löschen des Eintrags!', error);
            if (error.response && error.response.data && error.response.data.message) {
                alert(`Fehler: ${error.response.data.message}`);
            } else {
                alert('Fehler beim Löschen des Eintrags');
            }
        }
    };

    const handleClose = () => {
        setOpen(false); // Modal schließen
    };


    // Spaltendefinitionen
    const columns = [
        { id: 'datum', label: 'Datum', sortable: true },
        { id: 'funkcode', label: 'Funkcode', sortable: true },
        { id: 'kennzeichen', label: 'Kennzeichen', sortable: true },
        { id: 'km_stand', label: 'KM Stand', sortable: true },
        { id: 'profiltiefe_vl', label: 'Profiltiefe VL', sortable: false },
        { id: 'profiltiefe_vr', label: 'Profiltiefe VR', sortable: false },
        { id: 'profiltiefe_hl', label: 'Profiltiefe HL', sortable: false },
        { id: 'profiltiefe_hr', label: 'Profiltiefe HR', sortable: false },
        { id: 'getankte_liter', label: 'Getankte Liter', sortable: false },
    ];

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Monat zweistellig
        const day = ('0' + date.getDate()).slice(-2); // Tag zweistellig
        return `${year}-${month}-${day}`; // Ausgabe: YYYY-MM-DD
    };


    const formatDateForInput = (isoString) => {
        const date = new Date(isoString);
        if (isNaN(date.getTime())) {
            console.error('Ungültiges Datum:', isoString);
            return '';
        }
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Monat zweistellig formatieren
        const day = ('0' + date.getDate()).slice(-2); // Tag zweistellig formatieren
        return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
    };

    return (
        <div className={`main-container ${darkMode ? 'dark' : ''}`}>
            <h1>Kilometerstände</h1>

            {/* Filter und Suche */}
            <Grid2 container spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                <Grid2 container xs={12} sm={9} md={9} justifyContent="flex-start" spacing={2}>
                    <Grid2 item xs={12} sm={4} md={4}>
                        <TextField
                            label="Suche"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={searchTerm}
                            size="small"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 item xs={12} sm={4} md={4}>
                        <TextField
                            select
                            label="Jahr"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{ minWidth: 120 }}
                        >
                            <MenuItem value="">keine Auswahl</MenuItem>
                            {years.map((year) => (
                                <MenuItem key={year} value={year.toString()}>
                                    {year}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid2>
                    <Grid2 item xs={12} sm={4} md={4}>
                        <TextField
                            select
                            label="Monat"
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{ minWidth: 120 }}
                        >
                            <MenuItem value="">keine Auswahl</MenuItem>
                            {months.map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid2>
                    <Grid2 item xs={12} sm={4} md={4}>
                        <TextField
                            select
                            label="Funkcode"
                            value={selectedFunkcode}
                            onChange={(e) => setSelectedFunkcode(e.target.value)}
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{ minWidth: 120 }}
                        >
                            <MenuItem value="">keine Auswahl</MenuItem>
                            {funkcodes.map((f) => (
                                <MenuItem key={f.funkcode} value={f.funkcode}>
                                    {f.funkcode}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid2>
                </Grid2>

                {/* Button zum Öffnen des Modals */}
                {permissions.can_write && (
                    <Grid2 container item xs={12} sm={3} md={3} justifyContent="flex-end">
                        <IconButton onClick={() => setOpen(true)} color="primary">
                            <AddIcon />
                        </IconButton>
                    </Grid2>
                )}
            </Grid2>

            {/* Modal für den neuen Eintrag */}
            {permissions.can_write && (
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Neuen Kilometerstand Eintrag</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Datum"
                            type="date"
                            value={newEntry.date}
                            onChange={(e) => setNewEntry({ ...newEntry, date: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            select
                            label="Funkcode"
                            value={newEntry.funkcode}
                            onChange={(e) => setNewEntry({ ...newEntry, funkcode: e.target.value })}
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{ minWidth: 120 }}
                        >
                            <MenuItem value="">keine Auswahl</MenuItem>
                            {funkcodes.map(f => (
                                <MenuItem key={f.funkcode} value={f.funkcode}>
                                    {f.funkcode}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="KM Stand"
                            value={newEntry.km_stand}
                            onChange={(e) => setNewEntry({ ...newEntry, km_stand: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Profiltiefe VL"
                            value={newEntry.profiltiefe_vl}
                            onChange={(e) => setNewEntry({ ...newEntry, profiltiefe_vl: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Profiltiefe VR"
                            value={newEntry.profiltiefe_vr}
                            onChange={(e) => setNewEntry({ ...newEntry, profiltiefe_vr: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Profiltiefe HL"
                            value={newEntry.profiltiefe_hl}
                            onChange={(e) => setNewEntry({ ...newEntry, profiltiefe_hl: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Profiltiefe HR"
                            value={newEntry.profiltiefe_hr}
                            onChange={(e) => setNewEntry({ ...newEntry, profiltiefe_hr: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Getankte Liter"
                            value={newEntry.getankte_liter}
                            onChange={(e) => setNewEntry({ ...newEntry, getankte_liter: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Abbrechen</Button>
                        <Button onClick={handleCreateEntry} color="primary" variant="contained">Speichern</Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Modal für das Bearbeiten eines Eintrags */}
            {permissions.can_write && (
                <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
                    <DialogTitle>Eintrag bearbeiten</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Datum"
                            type="date"
                            value={currentEntry?.date || ''}  // Verwende 'date', um sicherzustellen, dass das Datum korrekt angezeigt wird
                            onChange={(e) => setCurrentEntry({ ...currentEntry, date: e.target.value })}  // Aktualisiere 'date'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        <TextField
                            select
                            label="Funkcode"
                            value={currentEntry?.funkcode || ''}
                            onChange={(e) => setCurrentEntry({ ...currentEntry, funkcode: e.target.value })}
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{ minWidth: 120 }}
                        >
                            <MenuItem value="">keine Auswahl</MenuItem>
                            {funkcodes.map((f) => (
                                <MenuItem key={f.funkcode} value={f.funkcode}>
                                    {f.funkcode}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="KM Stand"
                            value={currentEntry?.km_stand || ''}
                            onChange={(e) => setCurrentEntry({ ...currentEntry, km_stand: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Profiltiefe VL"
                            value={currentEntry?.profiltiefe_vl || ''}
                            onChange={(e) => setCurrentEntry({ ...currentEntry, profiltiefe_vl: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Profiltiefe VR"
                            value={currentEntry?.profiltiefe_vr || ''}
                            onChange={(e) => setCurrentEntry({ ...currentEntry, profiltiefe_vr: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Profiltiefe HL"
                            value={currentEntry?.profiltiefe_hl || ''}
                            onChange={(e) => setCurrentEntry({ ...currentEntry, profiltiefe_hl: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Profiltiefe HR"
                            value={currentEntry?.profiltiefe_hr || ''}
                            onChange={(e) => setCurrentEntry({ ...currentEntry, profiltiefe_hr: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                        <TextField
                            label="Getankte Liter"
                            value={currentEntry?.getankte_liter || ''}
                            onChange={(e) => setCurrentEntry({ ...currentEntry, getankte_liter: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, step: '0.1' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenEditModal(false)} color="primary">Abbrechen</Button>
                        <Button onClick={handleSaveEdit} color="primary" variant="contained">Speichern</Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Tabelle zur Anzeige der Kilometerstand-Einträge */}
            <TableContainer component={Paper} sx={{ backgroundColor: darkMode ? 'transparent' : '#ffffff' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id}>
                                    {column.sortable ? (
                                        <TableSortLabel
                                            active={sortConfig.key === column.id}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort(column.id)}
                                            sx={{ color: darkMode ? '#ffffff' : '#000000' }}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        column.label
                                    )}
                                </TableCell>
                            ))}
                            {permissions.can_write && <TableCell>Aktionen</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((entry) => (
                            <TableRow key={entry.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        {column.id === 'datum'
                                            ? new Date(entry[column.id]).toLocaleDateString()
                                            : entry[column.id]}
                                    </TableCell>
                                ))}

                                {permissions.can_write && (
                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(entry)} color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(entry.id)} color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

};

export default Kilometerliste;
