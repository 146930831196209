// src/pages/News.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Button, TextField, Checkbox, FormControlLabel, IconButton, Paper, Typography, Box, Badge
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Comment as CommentIcon, Add as AddIcon } from '@mui/icons-material';
import { PermissionsContext } from '../context/PermissionsContext'; // Importiere den PermissionsContext
import '../assets/styles/Main.css';
import '../assets/styles/News.css';

const News = ({ darkMode }) => {
    const permissions = useContext(PermissionsContext); // Zugriff auf globale Berechtigungen

    const [newsEntries, setNewsEntries] = useState([]);
    const [comments, setComments] = useState({});
    const [commentContent, setCommentContent] = useState({});
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [published, setPublished] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [newsToDelete, setNewsToDelete] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [currentNewsId, setCurrentNewsId] = useState(null);
    const [openComments, setOpenComments] = useState({});
    const [isEditingComment, setIsEditingComment] = useState(null); // Um zu steuern, welcher Kommentar bearbeitet wird
    const [editedComment, setEditedComment] = useState(''); // Um den bearbeiteten Kommentar zu speichern

    // Zusätzlicher State für Benutzer-Fullnamen
    const [userFullNames, setUserFullNames] = useState({});

    useEffect(() => {
        if (permissions.can_read) {
            fetchNews();
        }
    }, [permissions.can_read]);

    const fetchNews = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setNewsEntries(response.data);

            // Für jede News die Kommentare abrufen
            response.data.forEach(news => {
                fetchComments(news.id);
                fetchUserFullName(news.author);
            });
        } catch (error) {
            console.error('Fehler beim Abrufen der News:', error);
        }
    };

    const fetchComments = async (newsId) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news/${newsId}/comments`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setComments((prevComments) => ({
                ...prevComments,
                [newsId]: response.data,
            }));

            // Hol die vollständigen Namen der Kommentar-Autoren
            response.data.forEach(comment => {
                fetchUserFullName(comment.author);
            });
        } catch (error) {
            console.error('Fehler beim Abrufen der Kommentare:', error);
        }
    };

    const fetchUserFullName = async (username) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${username}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Debugging, um die API-Antwort zu sehen
            console.log('API-Antwort für Benutzername:', response.data);

            // Speichere Vor- und Nachname
            setUserFullNames((prev) => ({
                ...prev,
                [username]: `${response.data.vorname} ${response.data.nachname}`
            }));
        } catch (error) {
            console.error(`Fehler beim Abrufen des vollständigen Namens für ${username}:`, error);
        }
    };


    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        resetForm();
    };

    const resetForm = () => {
        setTitle('');
        setContent('');
        setPublished(false);
        setIsEditing(false);
        setCurrentNewsId(null);
    };

    const createOrEditNews = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (isEditing) {
                await axios.put(`${process.env.REACT_APP_API_URL}/news/${currentNewsId}`, {
                    title,
                    content,
                    is_published: published,
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/news`, {
                    title,
                    content,
                    is_published: published,
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }
            fetchNews();
            handleCloseModal();
        } catch (error) {
            console.error('Fehler beim Erstellen oder Bearbeiten der News:', error);
        }
    };

    const handleEditClick = (news) => {
        setTitle(news.title);
        setContent(news.content);
        setPublished(news.is_published);
        setCurrentNewsId(news.id);
        setIsEditing(true);
        handleOpenModal();
    };

    const handleDeleteClick = (id) => {
        setNewsToDelete(id);
        setOpenConfirm(true);
    };

    const confirmDelete = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (newsToDelete) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/news/${newsToDelete}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setNewsEntries(newsEntries.filter(news => news.id !== newsToDelete));
                setOpenConfirm(false);
            }
        } catch (error) {
            console.error('Fehler beim Löschen der News:', error);
        }
    };

    const toggleComments = (newsId) => {
        setOpenComments((prev) => ({
            ...prev,
            [newsId]: !prev[newsId]
        }));
    };

    const handleCommentChange = (newsId, value) => {
        setCommentContent((prevContent) => ({
            ...prevContent,
            [newsId]: value,
        }));
    };

    const addComment = async (newsId) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.post(`${process.env.REACT_APP_API_URL}/news/${newsId}/comments`, {
                content: commentContent[newsId],
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCommentContent((prevContent) => ({
                ...prevContent,
                [newsId]: '',
            }));
            fetchComments(newsId);
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Kommentars:', error);
        }
    };

    const deleteComment = async (newsId, commentId) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`${process.env.REACT_APP_API_URL}/news/${newsId}/comments/${commentId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            fetchComments(newsId);
        } catch (error) {
            console.error('Fehler beim Löschen des Kommentars:', error);
        }
    };

    const handleEditComment = (comment) => {
        setIsEditingComment(comment.id);
        setEditedComment(comment.content);
    };

    const saveEditedComment = async (newsId, commentId) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.put(`${process.env.REACT_APP_API_URL}/news/${newsId}/comments/${commentId}`, {
                content: editedComment,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setIsEditingComment(null); // Bearbeitungsmodus beenden
            fetchComments(newsId); // Aktualisiere die Kommentare nach dem Speichern
        } catch (error) {
            console.error('Fehler beim Bearbeiten des Kommentars:', error);
        }
    };

    return (
        <div className={`main-container ${darkMode ? 'dark' : ''}`}>
            <Typography variant="h4" className="news-title">News</Typography>

            {/* Button für neuen News-Eintrag, nur sichtbar wenn can_write */}
            {permissions.can_write && (
                <Button variant="contained" color="primary" onClick={handleOpenModal} startIcon={<AddIcon />}>
                    Neuer News-Eintrag
                </Button>
            )}

            {/* News List */}
            <Box mt={4}>
                {permissions.can_read ? (
                    newsEntries.length > 0 ? (
                        newsEntries.map((news) => (
                            <Paper key={news.id} className={`news-entry ${darkMode ? 'dark-entry' : ''}`} elevation={3} sx={{ p: 2, mb: 3 }}>
                                {/* Titel und Status */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Typography variant="h5" className={`news-entry-title ${darkMode ? 'dark-title' : ''}`}>{news.title}</Typography>
                                    <Typography variant="subtitle1" className={`news-entry-status ${news.is_published ? 'published' : 'unpublished'}`}>
                                        {news.is_published ? 'Veröffentlicht' : 'Nicht veröffentlicht'}
                                    </Typography>
                                </Box>

                                {/* Inhalt der News */}
                                <Typography variant="body1" mb={2} style={{ whiteSpace: 'pre-wrap' }}>{news.content}</Typography>

                                {/* Aktionen: Kommentare, Bearbeiten, Löschen */}
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                        {/* Button für Kommentare mit Badge */}
                                        <Badge badgeContent={comments[news.id]?.length || 0} color="error" overlap="circular">
                                            <IconButton onClick={() => toggleComments(news.id)} color="primary">
                                                <CommentIcon />
                                            </IconButton>
                                        </Badge>

                                        {/* Bearbeiten- und Löschen-Buttons, nur sichtbar wenn can_write */}
                                        {permissions.can_write && (
                                            <>
                                                <IconButton onClick={() => handleEditClick(news)} color="primary">
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteClick(news.id)} color="error">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>

                                    {/* Ersteller: Vor- und Nachname */}
                                    <Typography variant="caption" color="textSecondary">
                                        Erstellt von: {userFullNames[news.author] || 'Unbekannt'}
                                    </Typography>
                                </Box>

                                {/* Kommentare */}
                                {openComments[news.id] && (
                                    <Box mt={2} className="comment-section">
                                        <Typography variant="h6">Kommentare</Typography>
                                        <Box className="comments-list" mt={1}>
                                            {comments[news.id] && comments[news.id].length > 0 ? (
                                                comments[news.id].map((comment) => (
                                                    <Box key={comment.id} className="comment-entry" mb={1} p={1} bgcolor={darkMode ? '#333' : '#f9f9f9'} borderRadius={2}>
                                                        {isEditingComment === comment.id ? (
                                                            <Box display="flex" alignItems="center">
                                                                <TextField
                                                                    value={editedComment}
                                                                    onChange={(e) => setEditedComment(e.target.value)}
                                                                    fullWidth
                                                                    multiline
                                                                    rows={2}
                                                                    variant="outlined"
                                                                />
                                                                <Button color="primary" onClick={() => saveEditedComment(news.id, comment.id)} sx={{ ml: 1 }}>
                                                                    Speichern
                                                                </Button>
                                                                <Button color="secondary" onClick={() => setIsEditingComment(null)} sx={{ ml: 1 }}>
                                                                    Abbrechen
                                                                </Button>
                                                            </Box>
                                                        ) : (
                                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                <Typography variant="body2">
                                                                    <strong>{userFullNames[comment.author] || 'Unbekannt'}:</strong> {comment.content}
                                                                </Typography>
                                                                {permissions.can_write && (
                                                                    <Box>
                                                                        <IconButton size="small" onClick={() => handleEditComment(comment)} color="primary">
                                                                            <EditIcon fontSize="small" />
                                                                        </IconButton>
                                                                        <IconButton size="small" onClick={() => deleteComment(news.id, comment.id)} color="error">
                                                                            <DeleteIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                ))
                                            ) : (
                                                <Typography variant="body2">Keine Kommentare vorhanden.</Typography>
                                            )}
                                        </Box>

                                        {/* Kommentar hinzufügen, nur wenn can_write */}
                                        {permissions.can_write && (
                                            <Box display="flex" alignItems="center" mt={2}>
                                                <TextField
                                                    label="Kommentar schreiben"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                    value={commentContent[news.id] || ''}
                                                    onChange={(e) => handleCommentChange(news.id, e.target.value)}
                                                    sx={{ mr: 2 }}
                                                />
                                                <Button variant="contained" color="primary" onClick={() => addComment(news.id)}>
                                                    Absenden
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Paper>
                        ))
                    ) : (
                        <Typography variant="body1">Keine News-Einträge verfügbar.</Typography>
                    )
                ) : (
                    <Typography variant="body1">Sie haben keine Berechtigung, die News anzusehen.</Typography>
                )}
            </Box>

            {/* Dialog für Neuerstellung oder Bearbeitung */}
            {permissions.can_write && (
                <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
                    <DialogTitle>{isEditing ? 'News bearbeiten' : 'Neuen News-Eintrag erstellen'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Titel"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Inhalt"
                            fullWidth
                            multiline
                            rows={4}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={published} onChange={(e) => setPublished(e.target.checked)} />}
                            label="Veröffentlichen"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={createOrEditNews} color="primary" variant="contained">
                            {isEditing ? 'Speichern' : 'Erstellen'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Bestätigungsdialog für Löschen */}
            {permissions.can_write && (
                <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
                    <DialogTitle>Bestätigung</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Möchten Sie diesen News-Eintrag wirklich löschen?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirm(false)} color="primary">Abbrechen</Button>
                        <Button onClick={confirmDelete} color="secondary">Löschen</Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default News;
