import React, { useEffect, useState } from 'react';
import { Typography, Container, Paper, Box } from '@mui/material';
import '../assets/styles/Main.css';

const Dashboard = ({ darkMode }) => {
    const [isFadingIn, setIsFadingIn] = useState(false);

    useEffect(() => {
        // Trigger den Fade-In-Effekt beim ersten Rendern
        setIsFadingIn(true);
    }, []);

    return (
        <div className={`main-container ${darkMode ? 'dark' : ''}`}>
            <Container maxWidth="md" sx={{ marginTop: 4 }}>
                <Paper
                    elevation={3}
                    className={isFadingIn ? 'fade-in' : ''}
                    sx={{ padding: 4 }}
                >
                    <Typography variant="h4" align="center" gutterBottom>
                        Willkommen im Dashboard
                    </Typography>
                    <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                        <Typography variant="body1">
                            Hier findest du eine Übersicht deiner Daten.
                        </Typography>
                    </Box>
                </Paper>
            </Container>
        </div>
    );
};

export default Dashboard;
