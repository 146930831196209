// src/pages/Chat.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Grid2'; // Grid2 importieren
import {
    TextField,
    IconButton,
    Button,
    List,
    ListItem,
    ListItemText,
    Avatar,
    Badge,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Box,
    useMediaQuery,
    Drawer,
} from '@mui/material';
import {
    Send as SendIcon,
    Add as AddIcon,
    MoreVert as MoreVertIcon,
    EmojiEmotions as EmojiEmotionsIcon,
    AttachFile as AttachFileIcon,
    Mic as MicIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';
import { PermissionsContext } from '../context/PermissionsContext';
import '../assets/styles/Main.css';
import '../assets/styles/Chat.css';

const Chat = ({ darkMode }) => {
    const permissions = useContext(PermissionsContext);
    const [chats, setChats] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [socket, setSocket] = useState(null);
    const [unreadCounts, setUnreadCounts] = useState({});
    const [openCreateChatDialog, setOpenCreateChatDialog] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [chatName, setChatName] = useState('');
    const [loading, setLoading] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);

    const authToken = localStorage.getItem('authToken');
    let userId = null;

    if (authToken) {
        try {
            const decoded = jwtDecode(authToken);
            userId = decoded.userId;
        } catch (error) {
            console.error('Fehler beim Dekodieren des Tokens:', error);
        }
    }

    const messagesEndRef = useRef(null);

    // Medienabfrage für responsives Design
    const isMobile = useMediaQuery('(max-width:768px)'); // Anpassung des Breakpoints nach Bedarf

    useEffect(() => {
        if (permissions.can_read && authToken) {
            const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
                auth: { token: authToken },
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('Mit dem Socket.IO-Server verbunden');
                newSocket.emit('joinChatList');
            });

            newSocket.on('chatList', (chatList) => {
                console.log('Chatliste empfangen:', chatList);
                setChats(chatList);
                chatList.forEach(chat => fetchUnreadMessages(chat.id));
            });

            return () => {
                newSocket.disconnect();
            };
        }
    }, [permissions.can_read, authToken]);

    useEffect(() => {
        if (socket) {
            socket.on('message', (message) => {
                console.log('Nachricht empfangen:', message);
                if (message.chat_id === selectedChatId) {
                    setMessages((prevMessages) => [...prevMessages, message]);
                } else {
                    setUnreadCounts((prevCounts) => ({
                        ...prevCounts,
                        [message.chat_id]: (prevCounts[message.chat_id] || 0) + 1,
                    }));
                }
                scrollToBottom();
            });

            socket.on('chatListUpdate', ({ chatId }) => {
                console.log('Chatliste aktualisiert, Chat-ID:', chatId);
                socket.emit('joinChatList');
            });

            return () => {
                socket.off('message');
                socket.off('chatListUpdate');
            };
        }
    }, [socket, selectedChatId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const fetchUnreadMessages = async (chatId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CHAT_API_URL}/chats/${chatId}/unread`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            console.log(`Ungelesene Nachrichten für Chat ${chatId}:`, response.data.unreadCount);
            setUnreadCounts((prev) => ({
                ...prev,
                [chatId]: response.data.unreadCount || 0,
            }));
        } catch (error) {
            console.error('Fehler beim Abrufen der ungelesenen Nachrichten:', error);
        }
    };

    const selectChat = (chatId) => {
        console.log('Ausgewählter Chat:', chatId);
        setSelectedChatId(chatId);
        fetchMessages(chatId);
        if (socket) {
            socket.emit('joinChat', { chatId });
        }

        axios.post(`${process.env.REACT_APP_CHAT_API_URL}/chats/${chatId}/read`, {}, {
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(() => {
                console.log(`Nachrichten für Chat ${chatId} als gelesen markiert`);
                setUnreadCounts((prevCounts) => ({
                    ...prevCounts,
                    [chatId]: 0,
                }));
            })
            .catch((error) => {
                console.error('Fehler beim Markieren der Nachrichten als gelesen:', error);
            });

        // Bei mobilen Geräten das Drawer schließen
        if (isMobile) {
            setMobileOpen(false);
        }
    };

    const fetchMessages = async (chatId) => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_CHAT_API_URL}/chats/${chatId}/messages`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            console.log('Nachrichten abgerufen:', response.data);
            setMessages(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Nachrichten:', error);
        } finally {
            setLoading(false);
        }
    };

    const sendMessage = () => {
        if (newMessage.trim() && selectedChatId) {
            console.log('Nachricht senden:', newMessage);
            socket.emit('chatMessage', {
                chatId: selectedChatId,
                content: newMessage,
            });
            setNewMessage('');
        }
    };

    const openCreateChat = () => {
        setOpenCreateChatDialog(true);
        fetchUsers();
    };

    const closeCreateChat = () => {
        setOpenCreateChatDialog(false);
        setSelectedUsers([]);
        setChatName('');
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CHAT_API_URL}/users`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            console.log('Benutzer abgerufen:', response.data);
            setUsers(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Benutzer:', error);
        }
    };

    const createChat = async () => {
        try {
            console.log('Erstelle Chat mit:', {
                name: chatName,
                participantIds: selectedUsers,
            });
            const response = await axios.post(`${process.env.REACT_APP_CHAT_API_URL}/chats`, {
                name: chatName,
                participantIds: selectedUsers,
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            console.log('Chat erstellt:', response.data);
            closeCreateChat();
            if (socket) {
                socket.emit('joinChatList');
            }
        } catch (error) {
            console.error('Fehler beim Erstellen des Chats:', error);
        }
    };

    const handleUserSelection = (userId) => {
        setSelectedUsers((prevSelected) => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter((id) => id !== userId);
            } else {
                return [...prevSelected, userId];
            }
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // Drawer für mobile Geräte
    const drawer = (
        <Box sx={{ width: 250 }}>
            {/* Chatliste */}
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} bgcolor="#ededed">
                <Typography variant="h6">Chats</Typography>
                <Box>
                    <IconButton onClick={openCreateChat}>
                        <AddIcon />
                    </IconButton>
                    <IconButton>
                        <MoreVertIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box flexGrow={1} overflow="auto">
                <List>
                    {chats.length > 0 ? (
                        chats.map((chat) => (
                            <ListItem
                                key={chat.id}
                                button
                                selected={selectedChatId === chat.id}
                                onClick={() => selectChat(chat.id)}
                            >
                                <Avatar sx={{ mr: 2 }}>{chat.display_name.charAt(0)}</Avatar>
                                <ListItemText
                                    primary={chat.display_name}
                                    secondary={chat.last_message ? chat.last_message.content : ''}
                                />
                                {unreadCounts[chat.id] > 0 && (
                                    <Badge badgeContent={unreadCounts[chat.id]} color="error" />
                                )}
                            </ListItem>
                        ))
                    ) : (
                        <Typography variant="body1" align="center" mt={2}>
                            Keine Chats verfügbar.
                        </Typography>
                    )}
                </List>
            </Box>
        </Box>
    );

    return (
        <div className={`main-container ${darkMode ? 'dark' : ''}`} style={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Mobile AppBar */}
            {isMobile && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        bgcolor: '#ededed',
                        borderBottom: '1px solid #ccc',
                    }}
                >
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">
                        {selectedChatId ? chats.find(chat => chat.id === selectedChatId)?.display_name : 'Chats'}
                    </Typography>
                </Box>
            )}

            <Grid2 container sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
                {/* Desktop Chat-Liste */}
                {!isMobile && (
                    <Grid2
                        xs={12}
                        sm={4}
                        md={3}
                        lg={3}
                        sx={{
                            borderRight: '1px solid #ccc',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        }}
                    >
                        {/* Chat-Kopfzeile */}
                        <Box display="flex" alignItems="center" justifyContent="space-between" p={2} bgcolor="#ededed">
                            <Typography variant="h6">Chats</Typography>
                            <Box>
                                <IconButton onClick={openCreateChat}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        </Box>

                        {/* Chat-Liste */}
                        <Box flexGrow={1} overflow="auto">
                            <List>
                                {chats.length > 0 ? (
                                    chats.map((chat) => (
                                        <ListItem
                                            key={chat.id}
                                            button
                                            selected={selectedChatId === chat.id}
                                            onClick={() => selectChat(chat.id)}
                                        >
                                            <Avatar sx={{ mr: 2 }}>{chat.display_name.charAt(0)}</Avatar>
                                            <ListItemText
                                                primary={chat.display_name}
                                                secondary={chat.last_message ? chat.last_message.content : ''}
                                            />
                                            {unreadCounts[chat.id] > 0 && (
                                                <Badge badgeContent={unreadCounts[chat.id]} color="error" />
                                            )}
                                        </ListItem>
                                    ))
                                ) : (
                                    <Typography variant="body1" align="center" mt={2}>
                                        Keine Chats verfügbar.
                                    </Typography>
                                )}
                            </List>
                        </Box>
                    </Grid2>
                )}

                {/* Nachrichten-Bereich */}
                <Grid2
                    xs={12}
                    sm={isMobile ? 12 : 8}
                    md={isMobile ? 12 : 9}
                    lg={isMobile ? 12 : 9}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    {selectedChatId ? (
                        <>
                            {/* Chat-Kopfzeile (nur Desktop) */}
                            {!isMobile && (
                                <Box display="flex" alignItems="center" p={2} bgcolor="#ededed">
                                    <Avatar>{chats.find(chat => chat.id === selectedChatId)?.display_name.charAt(0)}</Avatar>
                                    <Typography variant="h6" ml={2}>
                                        {chats.find(chat => chat.id === selectedChatId)?.display_name}
                                    </Typography>
                                </Box>
                            )}

                            {/* Nachrichten-Liste */}
                            <Box
                                flexGrow={1}
                                p={2}
                                bgcolor="#f5f5f5"
                                className="messages-container"
                                sx={{ overflowY: 'auto', width: '100%' }}
                            >
                                {loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    messages.map((msg, index) => (
                                        <Box
                                            key={index}
                                            className={`message-wrapper ${msg.sender_id === userId ? 'message-own' : 'message-other'}`}
                                        >
                                            <Box className="message-bubble">
                                                <Typography variant="body1">{msg.content}</Typography>
                                            </Box>
                                            <Typography variant="caption" className="message-time">
                                                {new Date(msg.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </Typography>
                                        </Box>
                                    ))
                                )}
                                <div ref={messagesEndRef} />
                            </Box>

                            {/* Nachrichteneingabe */}
                            {permissions.can_write && (
                                <Box display="flex" alignItems="center" p={1} bgcolor="#f0f0f0">
                                    <IconButton>
                                        <EmojiEmotionsIcon />
                                    </IconButton>
                                    <IconButton>
                                        <AttachFileIcon />
                                    </IconButton>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Nachricht schreiben"
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        sx={{ mx: 1, backgroundColor: '#fff', borderRadius: '20px' }}
                                    />
                                    {newMessage.trim() ? (
                                        <IconButton color="primary" onClick={sendMessage}>
                                            <SendIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton>
                                            <MicIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Typography variant="h6">Wähle einen Chat aus, um Nachrichten anzuzeigen.</Typography>
                        </Box>
                    )}
                </Grid2>
            </Grid2>

            {/* Drawer für mobile Geräte */}
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Bessere Performance auf mobilen Geräten
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
                }}
            >
                {drawer}
            </Drawer>

            {/* Dialog zum Erstellen eines neuen Chats */}
            <Dialog open={openCreateChatDialog} onClose={closeCreateChat} fullWidth maxWidth="sm">
                <DialogTitle>Neuen Chat erstellen</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Chat-Name"
                        fullWidth
                        value={chatName}
                        onChange={(e) => setChatName(e.target.value)}
                    />
                    <Typography variant="subtitle1" mt={2}>Teilnehmer auswählen:</Typography>
                    <Box>
                        {users.map((user) => (
                            <FormControlLabel
                                key={user.id}
                                control={
                                    <Checkbox
                                        checked={selectedUsers.includes(user.id)}
                                        onChange={() => handleUserSelection(user.id)}
                                    />
                                }
                                label={`${user.vorname} ${user.nachname}`}
                            />
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCreateChat} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={createChat} color="primary" variant="contained">
                        Erstellen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Chat;
