// src/pages/Kalender.js
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';
import 'moment/locale/de';
import deLocale from '@fullcalendar/core/locales/de';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Select, MenuItem, FormControl, InputLabel, IconButton, Grid2 } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axios from 'axios';
import '../assets/styles/Kalender.css'; // Dein Kalender CSS
moment.locale('de');
const getColorForArt = (art) => {
    switch (art) {
        case 'Ambulanzdienst':
            return '#1abc9c'; // Grün
        case 'Werkstatt':
            return '#e74c3c'; // Rot
        case 'Service':
            return '#3498db'; // Blau
        case 'Pickerl':
            return '#9b59b6'; // Lila
        case 'Reparatur-Schaden':
            return '#f1c40f'; // Gelb
        case 'Garantiefall':
            return '#e67e22'; // Orange
        default:
            return '#95a5a6'; // Grau für unbekannte Typen
    }
};

const Kalender = () => {
    const [events, setEvents] = useState([]); // Speicher für die Ereignisse
    const [open, setOpen] = useState(false); // Dialogfenster steuern
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [newEvent, setNewEvent] = useState({
        start: null,  // Stelle sicher, dass es `null` ist, wenn kein Datum vorhanden ist
        end: null,
        info: '',
        funkcode: '',
        art: ''
    });
    const [hoveredEvent, setHoveredEvent] = useState(null);
    const [funkcodes, setFunkcodes] = useState([]);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });



    useEffect(() => {
        const fetchFuhrparkData = async () => {
            try {
                const token = localStorage.getItem('authToken');  // Hole das Token aus dem LocalStorage
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/fuhrpark`, {
                    headers: {
                        Authorization: `Bearer ${token}`  // Füge das Token dem Authorization-Header hinzu
                    }
                });

                // Extrahiere die Funkcodes aus den Fuhrpark-Daten
                const funkcodes = response.data.map((item) => item.funkcode);
                setFunkcodes(funkcodes);
            } catch (error) {
                console.error('Fehler beim Abrufen der Fuhrpark-Daten:', error);
            }
        };

        fetchFuhrparkData();  // Hier wird die Funktion aufgerufen
        fetchCalendarEntries();  // Rufe auch die Kalendereinträge ab
    }, []);  // Hier gibt es keinen weiteren useEffect mehr





    // Funktion zum Abrufen der Kalendereinträge aus der Datenbank
    // Funktion zum Abrufen der Kalendereinträge aus der Datenbank
    const fetchCalendarEntries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/calendar-entries`);
            const formattedEvents = response.data.map(entry => ({
                id: entry.id,
                title: `${entry.funkcode} - ${entry.art}`, // Titel besteht nur aus Funkcode und Art
                start: moment(entry.start).toDate(),
                end: moment(entry.end).toDate(),
                info: entry.info,
                funkcode: entry.funkcode,
                art: entry.art,
                ort: entry.ort,  // Ort hinzugefügt
                color: getColorForArt(entry.art),
            }));
            setEvents(formattedEvents);
        } catch (error) {
            console.error('Fehler beim Abrufen der Kalendereinträge:', error);
        }
    };

    const handleEventClick = (clickInfo) => {
        const event = clickInfo.event;
        console.log('Event Object:', event);
        setSelectedEvent(event);
        setNewEvent({
            id: event._def.publicId,
            title: event.title,
            start: moment(event.start).format('YYYY-MM-DDTHH:mm'),
            end: moment(event.end).format('YYYY-MM-DDTHH:mm'),
            funkcode: event.extendedProps.funkcode || '',  // Verwende extendedProps für benutzerdefinierte Felder
            art: event.extendedProps.art || '',            // Verwende extendedProps für benutzerdefinierte Felder
            ort: event.extendedProps.ort || '',            // Verwende extendedProps für benutzerdefinierte Felder
            info: event.extendedProps.info || '',          // Verwende extendedProps für benutzerdefinierte Felder
        });
        console.log('id:', event._def.publicId);
        console.log('Selected Event - handleEventClick:', selectedEvent);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedEvent(null);
        setNewEvent({ title: '', start: '', end: '', funkcode: '', art: '', ort: '', info: '' });
        fetchCalendarEntries();
    };

    // Funktion zum Speichern eines neuen Eintrags
    const handleEventSave = async () => {
        try {
            const formattedEvent = {
                start: moment(newEvent.start).format('YYYY-MM-DD HH:mm:ss'),  // 24h Format für PostgreSQL
                end: moment(newEvent.end).format('YYYY-MM-DD HH:mm:ss'),      // 24h Format für PostgreSQL
                funkcode: newEvent.funkcode,
                art: newEvent.art,
                ort: newEvent.ort,
                info: newEvent.info,
            };

            console.log('Formatted Event:', formattedEvent);

            if (selectedEvent) {
                // Aktualisiere den bestehenden Eintrag
                console.log('Selected Event:', selectedEvent);  // Logge das gesamte ausgewählte Event
                console.log('Selected Event ID:', selectedEvent.id);  // Logge die ID des ausgewählten Events
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/calendar-entries/${selectedEvent.id}`, formattedEvent);
                console.log('PUT Response:', response.data);
                setEvents(events.map(event => (event.id === selectedEvent.id ? { ...event, ...formattedEvent } : event)));
            } else {
                // Erstelle einen neuen Eintrag
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/calendar-entries`, formattedEvent);
                console.log('POST Response:', response.data);
                setEvents([...events, { ...newEvent, id: response.data.id }]);
            }

            handleClose();
        } catch (error) {
            console.error('Fehler beim Speichern des Eintrags:', error);
        }
    };

    //DELETE Funktion
    const handleEventDelete = async (eventId) => {
        try {
            const deleteUrl = `${process.env.REACT_APP_API_URL}/calendar-entries/${eventId}`;
            console.log('DELETE Request URL:', deleteUrl);  // Debugging-Log

            // Lösche den Eintrag
            await axios.delete(deleteUrl);
            console.log('Event gelöscht:', eventId);

            // Aktualisiere den Zustand der Events
            setEvents(events.filter(event => event.id !== eventId));
            handleClose();
        } catch (error) {
            console.error('Fehler beim Löschen des Eintrags:', error);
        }
    };

    const handleEventDrop = async (info) => {
        const updatedEvent = {
            ...info.event.extendedProps,
            start: moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(info.event.end).format('YYYY-MM-DD HH:mm:ss')
        };

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/calendar-entries/${info.event.id}`, updatedEvent);
        } catch (error) {
            console.error('Fehler beim Aktualisieren nach dem Verschieben:', error);
        }
    };

    const handleEventResize = async (info) => {
        const updatedEvent = {
            ...info.event.extendedProps,
            start: moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(info.event.end).format('YYYY-MM-DD HH:mm:ss')
        };

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/calendar-entries/${info.event.id}`, updatedEvent);
        } catch (error) {
            console.error('Fehler beim Aktualisieren nach der Größenanpassung:', error);
        }
    };

    // Dialogfenster öffnen
    const handleSelectSlot = ({ start, end }) => {
        setNewEvent({ ...newEvent, start, end });
        setOpen(true);
    };

    const handleEventMouseEnter = (info) => {
        setHoveredEvent(info.event);
        setTooltipPosition({
            x: info.jsEvent.pageX,
            y: info.jsEvent.pageY,
        });
    };

    const handleEventMouseLeave = () => {
        setHoveredEvent(null);
    };

    return (
        <div className="calendar-container">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView='timeGridWeek'
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                events={events}
                editable={true}
                eventDrop={handleEventDrop}
                eventResize={handleEventResize}
                selectable
                eventClick={handleEventClick}
                select={handleSelectSlot}
                eventTimeFormat={{
                    hour: '2-digit', // 2-stellig
                    minute: '2-digit', // 2-stellig
                    hour12: false // 24-Stunden-Format
                }}
                slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false // Ändert das Format auf 24-Stunden
                }}
                eventMouseEnter={handleEventMouseEnter}
                eventMouseLeave={handleEventMouseLeave}
                height="100%" // 100% Höhe für den Kalender
                locale={deLocale}
                nowIndicator={true}
                dayMaxEventRows={4}
                eventColor={null}
            />

            {/* Dialog zum Hinzufügen eines neuen Ereignisses */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{selectedEvent ? 'Ereignis bearbeiten' : 'Neues Ereignis hinzufügen'}</DialogTitle>
                <DialogContent>
                    <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="funkcode-label">Funkcode</InputLabel>
                            <Select
                                label="Funkcode"
                                fullWidth
                                value={newEvent.funkcode}
                                onChange={(e) => setNewEvent({ ...newEvent, funkcode: e.target.value })}
                            >
                                {funkcodes.map((code, index) => (
                                    <MenuItem key={index} value={code}>
                                        {code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="art-label">Art</InputLabel>
                            <Select
                                label="Art"
                                fullWidth
                                value={newEvent.art}
                                onChange={(e) => setNewEvent({ ...newEvent, art: e.target.value })}
                            >
                                {['Ambulanzdienst', 'Service', 'Pickerl', 'Reparatur-Schaden', 'Garantiefall'].map((artOption) => (
                                    <MenuItem key={artOption} value={artOption}>
                                        {artOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="ort-label">Ort</InputLabel>
                            <Select
                                label="Ort"
                                fullWidth
                                value={newEvent.ort}
                                onChange={(e) => setNewEvent({ ...newEvent, ort: e.target.value })}
                            >
                                {['Pappas St. Johann', 'Vierthaler St. Johann', 'KFZ Wallner Salzburg'].map((ortOption) => (
                                    <MenuItem key={ortOption} value={ortOption}>
                                        {ortOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginTop: '16px', marginBottom: '16px' }}></div>
                        <TextField
                            marginTop="16px"
                            label="Info"
                            fullWidth
                            value={newEvent.info}
                            onChange={(e) => setNewEvent({ ...newEvent, info: e.target.value })}
                        />
                    </div>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid2 container spacing={2}> {/* Use MUI Grid2 with spacing */}
                            <Grid2 xs={6}> {/* First DateTimePicker takes half of the available space */}
                                <DateTimePicker
                                    label="Startdatum"
                                    value={newEvent.start ? moment(newEvent.start) : null}
                                    onChange={(date) => setNewEvent({ ...newEvent, start: date })}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    ampm={false}  // Disables AM/PM, enabling 24h format
                                    inputFormat="DD/MM/YYYY HH:mm"  // Specifies the format
                                />
                            </Grid2>
                            <Grid2 xs={6}> {/* Second DateTimePicker takes the other half */}
                                <DateTimePicker
                                    label="Enddatum"
                                    value={newEvent.end ? moment(newEvent.end) : null}
                                    onChange={(date) => setNewEvent({ ...newEvent, end: date })}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    ampm={false}  // Disables AM/PM, enabling 24h format
                                    inputFormat="DD/MM/YYYY HH:mm"  // Specifies the format
                                />
                            </Grid2>
                        </Grid2>

                    </LocalizationProvider>


                </DialogContent>
                <Grid2 container justifyContent="space-between">
                    <Grid2 item>
                        <DialogActions>
                            {selectedEvent && (
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleEventDelete(selectedEvent.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </DialogActions>
                    </Grid2>
                    <Grid2 item>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Abbrechen
                            </Button>
                            <Button onClick={handleEventSave} color="primary">
                                Speichern
                            </Button>
                        </DialogActions>
                    </Grid2>
                </Grid2>
            </Dialog>
            {hoveredEvent && (
                <div
                    style={{
                        position: 'absolute',
                        top: tooltipPosition.y + 10, // 10px unter der Maus
                        left: tooltipPosition.x + 10, // 10px rechts von der Maus
                        pointerEvents: 'none',
                        zIndex: 1000,
                        backgroundColor: '#fff',
                        padding: '8px',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                        fontSize: '12px',
                    }}
                >
                    <div><strong>Funkcode:</strong> {hoveredEvent.extendedProps.funkcode}</div>
                    <div><strong>Art:</strong> {hoveredEvent.extendedProps.art}</div>
                    <div><strong>Ort:</strong> {hoveredEvent.extendedProps.ort}</div>
                    <div><strong>Info:</strong> {hoveredEvent.extendedProps.info}</div>
                </div>
            )}
        </div>
    );
};

export default Kalender;
