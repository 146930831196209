// src/pages/Profil.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Grid2, TextField, Button, Typography, Box, Paper, CircularProgress } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { PermissionsContext } from '../context/PermissionsContext';

const Profil = ({ darkMode, setDarkMode }) => {
    const permissions = useContext(PermissionsContext);

    const [userData, setUserData] = useState({
        username: '',
        email: '',
        vorname: '',
        nachname: '',
        currentPassword: '',
        newPassword: ''
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            console.log('Fetching user data...');
            setLoading(true);
            const token = localStorage.getItem('authToken');
            if (!token) {
                setMessage('Keine Authentifizierung. Bitte erneut einloggen.');
                setLoading(false);
                return;
            }
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { username, email, vorname, nachname } = response.data;
                setUserData({
                    username: username || '',
                    email: email || '',
                    vorname: vorname || '',
                    nachname: nachname || '',
                    currentPassword: '',
                    newPassword: ''
                });
                console.log('User data fetched successfully:', response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setErrorMessage('Fehler beim Abrufen der Benutzerdaten.');
                setLoading(false);
            }
        };

        if (permissions.can_read) {
            fetchUserData();
        } else {
            console.log('Keine Leserechte vorhanden.');
            setLoading(false);
        }
    }, [permissions.can_read]);

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userData.newPassword !== confirmPassword) {
            setErrorMessage('Die neuen Passwörter stimmen nicht überein.');
            return;
        }
        if (permissions.can_write) {
            try {
                const token = localStorage.getItem('authToken');
                await axios.put(`${process.env.REACT_APP_API_URL}/profile`, userData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMessage('Daten erfolgreich aktualisiert.');
                setErrorMessage('');
            } catch (error) {
                console.error('Error updating user data:', error);
                setErrorMessage('Fehler beim Aktualisieren der Daten.');
                setMessage('');
            }
        } else {
            setErrorMessage('Zugriff verweigert. Keine Schreibrechte');
            setMessage('');
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={`main-container ${darkMode ? 'dark' : ''}`}>
            <Paper elevation={3} sx={{ padding: 4, maxWidth: 600, margin: 'auto', marginTop: 5 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Profil bearbeiten
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid2 container spacing={2} alignItems="flex-end">
                        <Grid2 container justifyContent="center">
                            {/* Benutzername */}
                            <Grid2 container alignItems="center" spacing={1}>
                                <Grid2 item xs={1} sm={2} md={1} display="flex" justifyContent="center">
                                    <AccountCircleIcon />
                                </Grid2>
                                <Grid2 item xs={11} sm={10} md={11}>
                                    <TextField
                                        label="Benutzername"
                                        name="username"
                                        value={userData.username}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled
                                    />
                                </Grid2>
                            </Grid2>

                            {/* E-Mail */}
                            <Grid2 container alignItems="center" spacing={1}>
                                <Grid2 item xs={1} sm={2} md={1} display="flex" justifyContent="center">
                                    <EmailIcon />
                                </Grid2>
                                <Grid2 item xs={11} sm={10} md={11}>
                                    <TextField
                                        label="E-Mail"
                                        name="email"
                                        type="email"
                                        value={userData.email}
                                        onChange={handleChange}
                                        disabled={!permissions.can_write}
                                        fullWidth
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>

                        <Grid2 container justifyContent="center">
                            {/* Vorname */}
                            <Grid2 container alignItems="center" spacing={1}>
                                <Grid2 item xs={1} sm={2} md={1} display="flex" justifyContent="center">
                                    <PersonIcon />
                                </Grid2>
                                <Grid2 item xs={11} sm={10} md={11}>
                                    <TextField
                                        label="Vorname"
                                        name="vorname"
                                        value={userData.vorname}
                                        onChange={handleChange}
                                        disabled={!permissions.can_write}
                                        fullWidth
                                    />
                                </Grid2>
                            </Grid2>

                            {/* Nachname */}
                            <Grid2 container alignItems="center" spacing={1}>
                                <Grid2 item xs={1} sm={2} md={1} display="flex" justifyContent="center">
                                    <PersonIcon />
                                </Grid2>
                                <Grid2 item xs={11} sm={10} md={11}>
                                    <TextField
                                        label="Nachname"
                                        name="nachname"
                                        value={userData.nachname}
                                        onChange={handleChange}
                                        disabled={!permissions.can_write}
                                        fullWidth
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>

                        <Grid2 container justifyContent="center">
                            {/* Aktuelles Passwort */}
                            <Grid2 container alignItems="center" spacing={1}>
                                <Grid2 item xs={1} sm={2} md={1} display="flex" justifyContent="center">
                                    <LockIcon />
                                </Grid2>
                                <Grid2 item xs={11} sm={10} md={11}>
                                    <TextField
                                        label="Aktuelles Passwort"
                                        name="currentPassword"
                                        type="password"
                                        value={userData.currentPassword}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={!permissions.can_write} // Optional: Je nach Bedarf aktivieren/deaktivieren
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>

                        <Grid2 container justifyContent="center">
                            {/* Neues Passwort */}
                            <Grid2 container alignItems="center" spacing={1}>
                                <Grid2 item xs={1} sm={2} md={1} display="flex" justifyContent="center">
                                    <LockIcon />
                                </Grid2>
                                <Grid2 item xs={11} sm={10} md={11}>
                                    <TextField
                                        label="Neues Passwort"
                                        name="newPassword"
                                        type="password"
                                        value={userData.newPassword}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={!permissions.can_write}
                                    />
                                </Grid2>
                                <Grid2 item xs={1} sm={2} md={1} display="flex" justifyContent="center">
                                    <LockIcon />
                                </Grid2>
                                <Grid2 item xs={11} sm={10} md={11}>
                                    <TextField
                                        label="Passwort bestätigen"
                                        name="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        fullWidth
                                        disabled={!permissions.can_write}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>

                    {/* Speichern Button */}
                    <Grid2 container justifyContent="center" sx={{ marginTop: 2 }}>
                        <Grid2 item xs={12} sm={6} md={4} lg={3}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Daten speichern
                                </Button>
                            </Box>
                        </Grid2>
                    </Grid2>

                    {message && (
                        <Typography variant="body1" color="success.main" align="center" gutterBottom>
                            {message}
                        </Typography>
                    )}
                    {errorMessage && (
                        <Typography variant="body1" color="error" align="center" gutterBottom>
                            {errorMessage}
                        </Typography>
                    )}
                </form>
            </Paper>
        </div>
    );
};

export default Profil;
