import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../assets/styles/Main.css'; // CSS für das Design
import './BaseLayout.css'; // CSS für das Design

const BaseLayout = ({ children, darkMode, setDarkMode }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [permissions, setPermissions] = useState({});

    // Responsive Sidebar Control
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsSidebarOpen(false); // Sidebar standardmäßig auf mobilen Geräten geschlossen
                setIsMobile(true);
            } else {
                setIsSidebarOpen(prevState => prevState); // Behalte den aktuellen Zustand der Sidebar bei
                setIsMobileSidebarOpen(false); // Mobile Sidebar schließen
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initiale Überprüfung der Fenstergröße
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const body = document.querySelector('body');
        if (darkMode) {
            body.classList.add('dark');
        } else {
            body.classList.remove('dark');
        }
    }, [darkMode]);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        navigate('/login');
    };

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const pages = ['fuhrpark', 'kilometerliste', 'news', 'dateien', 'rechteverwaltung', 'chat'];

                // Hole Berechtigungen für alle Seiten
                const permissions = {};
                for (const page of pages) {
                    const response = await axios.get(`/api/permissions/${page}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    permissions[page] = response.data.can_read;
                }

                setPermissions(permissions);
            } catch (error) {
                console.error('Fehler beim Abrufen der Berechtigungen:', error);
            }
        };

        fetchPermissions();
    }, []);

    return (
        <div className={`layout ${darkMode ? 'dark' : ''}`}>
            {/* Mobile Toggle Button */}
            {isMobile && (
                <div className="mobile-toggle" onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}>
                    <i className={`bx ${isMobileSidebarOpen ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
                </div>
            )}

            <nav className={`sidebar ${isSidebarOpen ? 'open' : 'closed'} ${isMobileSidebarOpen ? 'mobile-open' : 'mobile-closed'}`}>
                {/* Toggle Button für Desktop */}
                {!isMobile && (
                    <div className="toggle-btn" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        {isSidebarOpen ? (
                            <i className="bx bx-chevron-left"></i>
                        ) : (
                            <i className="bx bx-chevron-right"></i>
                        )}
                    </div>
                )}

                <ul>
                    <li className={location.pathname === "/" ? "active" : ""}>
                        <Link to="/">
                            <i className="bx bx-home"></i> <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className={location.pathname === "/fuhrpark" ? "active" : ""}>
                        <Link to="/fuhrpark">
                            <i className="bx bx-car"></i> <span>Fuhrpark</span>
                        </Link>
                    </li>
                    <li className={location.pathname === "/kilometerliste" ? "active" : ""}>
                        <Link to="/kilometerliste">
                            <i className="bx bx-list-ul"></i> <span>Kilometerliste</span>
                        </Link>
                    </li>
                    <li className={location.pathname === "/news" ? "active" : ""}>
                        <Link to="/news">
                            <i className="bx bx-news"></i> <span>News</span>
                        </Link>
                    </li>
                    <li className={location.pathname === "/dateien" ? "active" : ""}>
                        <Link to="/dateien">
                            <i className="bx bx-folder"></i> <span>Dateien</span>
                        </Link>
                    </li>
                    <li className={location.pathname === "/kalender" ? "active" : ""}>
                        <Link to="/kalender">
                            <i className="bx bx-calendar"></i> <span>Kalender</span>
                        </Link>
                    </li>
                    <li className={location.pathname === "/profil" ? "active" : ""}>
                        <Link to="/profil">
                            <i className="bx bx-user"></i> <span>Profil</span>
                        </Link>
                    </li>
                    {permissions['rechteverwaltung'] && (
                        <li className={location.pathname === "/rechteverwaltung" ? "active" : ""}>
                            <Link to="/rechteverwaltung">
                                <i className="bx bx-key"></i> <span>Rechteverwaltung</span>
                            </Link>
                        </li>
                    )}
                    {permissions['chat'] && (
                        <li className={location.pathname === "/chat" ? "active" : ""}>
                            <Link to="/chat">
                                <i className="bx bx-chat"></i> <span>Chat</span>
                            </Link>
                        </li>
                    )}
                    <li className="logout">
                        <div onClick={handleLogout} className="sidebar-link">
                            <i className="bx bx-log-out"></i> <span>Logout</span>
                        </div>
                    </li>
                </ul>

                {/* Dark Mode Toggle */}
                <div className="dark-mode-toggle">
                    <button onClick={() => setDarkMode(!darkMode)}>
                        {darkMode ? (
                            <i className="bx bx-sun"></i>
                        ) : (
                            <i className="bx bx-moon"></i>
                        )}
                    </button>
                </div>
            </nav>

            <div className={`content ${isSidebarOpen ? 'open' : 'closed'} ${isMobileSidebarOpen ? 'mobile-open' : 'mobile-closed'}`}>
                {children}
            </div>
        </div>
    );
};

export default BaseLayout;
