import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import { TextField, Button, Paper, Typography, Container, Box } from '@mui/material';
import '../assets/styles/Main.css';
import './LoginForm.css'; // Importiere die CSS-Datei für die Animation
import { Link } from 'react-router-dom';

const LoginForm = ({ darkMode, setDarkMode }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isAnimating, setIsAnimating] = useState(false); // State für die Animation
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { username, password });
            const token = response.data.token;
            const role = response.data.role; // Füge dies hinzu, um die Rolle korrekt zu holen

            localStorage.setItem('authToken', token);
            localStorage.setItem('username', username);
            localStorage.setItem('userRole', role); // Speichere die Rolle des Benutzers

            login(token); // Verwende die login-Funktion aus dem AuthContext

            // Animation starten
            setIsAnimating(true);

            // Nach 1s Animation zur Startseite navigieren
            setTimeout(() => {
                navigate('/');
            }, 1000);

        } catch (error) {
            setErrorMessage('Login fehlgeschlagen. Bitte überprüfe deine Daten.');
        }
    };





    return (
        <div className={`main-container ${darkMode ? 'dark' : ''}`}>
            <Container maxWidth="sm">
                <Paper
                    elevation={3}
                    className={isAnimating ? 'fade-out' : ''}
                    sx={{ padding: 4, marginTop: 8 }}
                >
                    <Typography variant="h4" align="center" gutterBottom>
                        Login
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ marginBottom: 2 }}>
                            <TextField
                                label="Benutzername"
                                variant="outlined"
                                fullWidth
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                InputProps={{ style: { fontSize: '16px' } }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <TextField
                                label="Passwort"
                                type="password"
                                variant="outlined"
                                fullWidth
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{ style: { fontSize: '16px' } }}
                            />
                        </Box>
                        {errorMessage && (
                            <Typography color="error" align="center" gutterBottom>
                                {errorMessage}
                            </Typography>
                        )}
                        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                            <Button type="submit" variant="contained" color="primary">
                                Login
                            </Button>
                        </Box>
                        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                            <Typography variant="body2">
                                Noch keinen Account? <Link to="/register">Hier registrieren</Link>
                            </Typography>
                        </Box>
                    </form>
                </Paper>
            </Container>
            <div className="dark-mode-toggle">
                <button onClick={() => setDarkMode(!darkMode)}>
                    {darkMode ? (
                        <i className="bx bx-sun"></i>
                    ) : (
                        <i className="bx bx-moon"></i>
                    )}
                </button>
            </div>
        </div>
    );
};

export default LoginForm;
