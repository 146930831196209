// src/context/PermissionsContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

// Erstellen des Kontextes
export const PermissionsContext = createContext();

// Erstellen des Provider-Komponenten
export const PermissionsProvider = ({ page, children }) => {
    const [permissions, setPermissions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPermissions = async () => {
            const token = localStorage.getItem('authToken');
            if (!token) {
                setError('Keine Authentifizierung. Bitte erneut einloggen.');
                setLoading(false);
                return;
            }
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/permissions/${page}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setPermissions(response.data);
                console.log('Permissions received:', response.data);
            } catch (err) {
                console.error('Fehler beim Abrufen der Berechtigungen:', err);
                if (err.response) {
                    setError(`Fehler: ${err.response.data.message || 'Beim Abrufen der Berechtigungen.'}`);
                } else if (err.request) {
                    setError('Keine Antwort vom Server erhalten.');
                } else {
                    setError('Ein unerwarteter Fehler ist aufgetreten.');
                }
                setPermissions({ can_read: false, can_write: false });
            } finally {
                setLoading(false);
            }
        };
        fetchPermissions();
    }, [page]);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error && !permissions) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>
                {error}
            </div>
        );
    }

    return (
        <PermissionsContext.Provider value={permissions}>
            {permissions.can_read ? children : <div>Zugriff verweigert</div>}
        </PermissionsContext.Provider>
    );
};
