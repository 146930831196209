import React, { createContext, useState, useEffect } from 'react';
import useTimeoutLogout from '../useTimeoutLogout'; // Die neue Timeout-Funktion importieren

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('authToken'));

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    const login = (token) => {
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
    };

    // Timeout für Inaktivität verwenden
    useTimeoutLogout(logout, 600000); // Timeout auf 10 Minuten (600000 Millisekunden) gesetzt

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
