import { useEffect } from 'react';

const useTimeoutLogout = (logout, timeoutDuration = 600000) => {
    useEffect(() => {
        let timeout;

        const resetTimeout = () => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                logout();
            }, timeoutDuration);
        };

        const handleActivity = () => {
            resetTimeout();
        };

        // Events, die überwacht werden, um die Inaktivität zurückzusetzen
        const events = ['click', 'mousemove', 'keypress', 'scroll', 'keydown'];

        events.forEach(event => window.addEventListener(event, handleActivity));

        resetTimeout();

        // Cleanup beim Verlassen der Komponente
        return () => {
            clearTimeout(timeout);
            events.forEach(event => window.removeEventListener(event, handleActivity));
        };
    }, [logout, timeoutDuration]);
};

export default useTimeoutLogout;
