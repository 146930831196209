import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    createTheme,
    ThemeProvider,
    TextField,
    Button,
    Tooltip,
} from '@mui/material';
import Grid2 from '@mui/material/Grid2'; // Grid2 importieren

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // Ihre Primärfarbe
        },
    },
});

const Rechteverwaltung = () => {
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [canAccessSite, setCanAccessSite] = useState(false);
    const [updatedUsers, setUpdatedUsers] = useState({});

    const pages = ['fuhrpark', 'kilometerliste', 'news', 'dateien', 'kalender', 'profil', 'rechteverwaltung', 'chat']; // Die Seiten, die Sie verwalten möchten

    const fetchCurrentUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });
            setCurrentUser(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen des aktuellen Benutzers:', error);
        }
    };
    const checkUserAccess = async () => {
        if (!currentUser) {
            setCanAccessSite(false);
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/permissions/rechteverwaltung`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });
            setCanAccessSite(response.data.can_read);
        } catch (error) {
            console.error('Fehler beim Überprüfen der Benutzerberechtigungen:', error);
            setCanAccessSite(false);
        }
    };



    // Funktion zum Abrufen der Rollen
    const fetchRoles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/roles`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });
            setRoles(response.data);
            console.log('Rollen:', response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Rollen:', error);
        }
    };

    // Funktion zum Abrufen der Benutzer
    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });
            setUsers(response.data);
            console.log('Benutzerdaten:', response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Benutzer:', error);
            setUsers([]);
        }
    };

    // useEffect zum Abrufen der Rollen und Benutzer beim Laden der Komponente
    useEffect(() => {
        const initialize = async () => {
            await fetchCurrentUser();
        };
        initialize();
    }, []);

    useEffect(() => {
        if (currentUser) {
            checkUserAccess();
            fetchRoles();
            fetchUsers();
        }
    }, [currentUser]);

    const handleUserDataChange = (userId, field, value) => {
        setUpdatedUsers({
            ...updatedUsers,
            [userId]: {
                ...updatedUsers[userId],
                [field]: value,
            },
        });
    };
    const handleUserUpdate = async (userId) => {
        try {
            // Verwende den aktuellen Stand der Benutzerdaten, um sicherzustellen, dass alle Felder vorhanden sind
            const userData = {
                ...users.find(user => user.id === userId), // Bestehende Daten des Benutzers
                ...updatedUsers[userId]                    // Überschreibe nur die Felder, die geändert wurden
            };

            console.log('Aktualisierte Benutzerdaten:', userData);

            await axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}`, userData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });

            // Benutzerdaten nach erfolgreicher Aktualisierung neu laden
            fetchUsers();

            // Entferne die gespeicherten Änderungen für diesen Benutzer
            setUpdatedUsers(prev => {
                const newState = { ...prev };
                delete newState[userId];
                return newState;
            });
        } catch (error) {
            console.error('Fehler beim Aktualisieren des Benutzers:', error);
        }
    };



    const handleRolePermissionChange = async (roleId, page, permissionType, value) => {
        try {
            // Aktuelle Berechtigungen für die Rolle und Seite abrufen
            const role = roles.find(r => r.id === roleId);
            const currentPermissions = role.permissions?.[page] || { can_read: false, can_write: false };

            let updatedPermissions = {
                can_read: currentPermissions.can_read,
                can_write: currentPermissions.can_write,
                [permissionType]: value,
            };

            // Wenn can_write auf true gesetzt wird, setzen wir can_read ebenfalls auf true
            if (permissionType === 'can_write' && value === true) {
                updatedPermissions.can_read = true;
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/permissions`, {
                roleId,
                page,
                ...updatedPermissions,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });

            // Aktualisieren Sie die Rollenliste, um die Änderungen widerzuspiegeln
            fetchRoles();
        } catch (error) {
            console.error('Fehler beim Ändern der Rollenberechtigungen:', error);
        }
    };


    const handleUserRoleChange = async (userId, roleId) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}/role`, { roleId }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });
            setUsers(users.map(user => (user.id === userId ? { ...user, role_id: roleId } : user)));
        } catch (error) {
            console.error('Fehler beim Ändern der Benutzerrolle:', error);
        }
    };

    return (

        <ThemeProvider theme={theme}>
            {canAccessSite && (
                <Container>
                    <Typography variant="h4" gutterBottom>
                        Rechteverwaltung
                    </Typography>

                    <Grid2 container spacing={4}>
                        {/* Rollenverwaltung */}

                        <Grid2 xs={12}>
                            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                                Rollenverwaltung
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell rowSpan={2}>Seite</TableCell>
                                            {roles.map((role) => (
                                                <TableCell key={role.id} colSpan={2} align="center">
                                                    {role.name}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            {roles.map((role) => (
                                                <React.Fragment key={role.id}>
                                                    <TableCell align="center">Lesen</TableCell>
                                                    <TableCell align="center">Schreiben</TableCell>
                                                </React.Fragment>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pages.map((page) => (
                                            <TableRow key={page}>
                                                <TableCell>{page}</TableCell>
                                                {roles.map((role) => (
                                                    <React.Fragment key={`${role.id}-${page}`}>
                                                        <TableCell align="center">
                                                            <Tooltip
                                                                title={
                                                                    role.permissions?.[page]?.can_write
                                                                        ? 'Leseberechtigung ist automatisch aktiviert, wenn Schreibberechtigung gesetzt ist.'
                                                                        : ''
                                                                }
                                                            >
                                                                <span>
                                                                    <Checkbox
                                                                        checked={role.permissions?.[page]?.can_read || false}
                                                                        onChange={(e) =>
                                                                            handleRolePermissionChange(
                                                                                role.id,
                                                                                page,
                                                                                'can_read',
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                        color="primary"
                                                                        disabled={role.permissions?.[page]?.can_write || false}
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Checkbox
                                                                checked={role.permissions?.[page]?.can_write || false}
                                                                onChange={(e) =>
                                                                    handleRolePermissionChange(
                                                                        role.id,
                                                                        page,
                                                                        'can_write',
                                                                        e.target.checked
                                                                    )
                                                                }
                                                                color="primary"
                                                            />
                                                        </TableCell>
                                                    </React.Fragment>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid2>

                    </Grid2>



                    {/* Benutzerverwaltung */}
                    <Grid2 xs={12}>
                        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                            Benutzerverwaltung
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Benutzername</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Vorname</TableCell>
                                        <TableCell>Nachname</TableCell>
                                        <TableCell>Rolle</TableCell>
                                        <TableCell>Aktionen</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map(user => (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.username}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={updatedUsers[user.id]?.email ?? user.email ?? ''}
                                                    onChange={(e) => handleUserDataChange(user.id, 'email', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={updatedUsers[user.id]?.vorname ?? user.vorname ?? ''}
                                                    onChange={(e) => handleUserDataChange(user.id, 'vorname', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={updatedUsers[user.id]?.nachname ?? user.nachname ?? ''}
                                                    onChange={(e) => handleUserDataChange(user.id, 'nachname', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormControl fullWidth variant="standard">
                                                    <InputLabel id={`role-select-label-${user.id}`}>Rolle</InputLabel>
                                                    <Select
                                                        labelId={`role-select-label-${user.id}`}
                                                        value={user.role_id ? user.role_id.toString() : ''}
                                                        onChange={(e) => handleUserRoleChange(user.id, e.target.value)}
                                                    >
                                                        {roles.map((role) => (
                                                            <MenuItem key={role.id} value={role.id.toString()}>
                                                                {role.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUserUpdate(user.id)}
                                                >
                                                    Speichern
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid2>


                </Container>
            )}
        </ThemeProvider>
    );
};

export default Rechteverwaltung;
