import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions,
    DialogContent, DialogTitle, TextField, Checkbox, FormControlLabel, TableSortLabel, IconButton, Popover, Grid2
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../assets/styles/Main.css';

const Fuhrpark = ({ darkMode }) => {
    const [fuhrparkData, setFuhrparkData] = useState([]);
    const [permissions, setPermissions] = useState({ can_read: false, can_write: false });
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
    const [openModal, setOpenModal] = useState(false);
    const [newVehicle, setNewVehicle] = useState({
        funkcode: '',
        kennzeichen: '',
        marke: '',
        modell: '',
        allrad: false,
        aktiv: false,
    });
    const [isEditing, setIsEditing] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [vehicleToDelete, setVehicleToDelete] = useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState({});
    const [filterValues, setFilterValues] = useState({
        funkcode: '',
        kennzeichen: '',
        marke: '',
        modell: '',
        allrad: null,
        aktiv: null,
    });

    const getAuthHeaders = () => {
        const token = localStorage.getItem('authToken');
        return { Authorization: `Bearer ${token}` };
    };

    useEffect(() => {
        const fetchFuhrparkData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/fuhrpark`, {
                    headers: getAuthHeaders()
                });
                setFuhrparkData(response.data);
            } catch (error) {
                console.error('Fehler beim Abrufen der Fuhrpark-Daten:', error);
            }
        };
        const fetchPermissions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/permissions/fuhrpark`, {
                    headers: getAuthHeaders()
                });
                setPermissions(response.data); // Setze die Berechtigungen für die Seite
            } catch (error) {
                console.error('Fehler beim Abrufen der Berechtigungen:', error);
            }
        };
        fetchFuhrparkData();
        fetchPermissions();
    }, []);

    const handleOpenModal = () => {
        if (permissions.can_write) {
            setOpenModal(true);
        } else {
            alert('Sie haben keine Berechtigung, um Fahrzeuge hinzuzufügen.');
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewVehicle({
            funkcode: '',
            kennzeichen: '',
            marke: '',
            modell: '',
            allrad: false,
            aktiv: false,
        });
        setIsEditing(false);
    };

    const addOrEditVehicle = async () => {
        if (!permissions.can_write) {
            alert('Sie haben keine Berechtigung, Fahrzeuge hinzuzufügen oder zu bearbeiten.');
            return;
        }
        try {
            if (isEditing) {
                await axios.put(`${process.env.REACT_APP_API_URL}/fuhrpark/${newVehicle.id}`, newVehicle, {
                    headers: getAuthHeaders()
                });
                setFuhrparkData((prevData) =>
                    prevData.map((vehicle) => (vehicle.id === newVehicle.id ? newVehicle : vehicle))
                );
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/fuhrpark`, newVehicle, {
                    headers: getAuthHeaders()
                });
                setFuhrparkData((prevData) => [...prevData, response.data]);
            }
            handleCloseModal();
        } catch (error) {
            console.error('Fehler beim Speichern des Eintrags:', error);
        }
    };

    const handleEdit = (vehicle) => {
        if (!permissions.can_write) {
            alert('Sie haben keine Berechtigung, Fahrzeuge zu bearbeiten.');
            return;
        }
        setNewVehicle(vehicle);
        setIsEditing(true);
        setOpenModal(true);
    };

    const handleOpenDeleteConfirm = (vehicle) => {
        if (!permissions.can_write) {
            alert('Sie haben keine Berechtigung, Fahrzeuge zu löschen.');
            return;
        }
        setVehicleToDelete(vehicle);
        setOpenDeleteConfirm(true);
    };

    const handleCloseDeleteConfirm = () => {
        setOpenDeleteConfirm(false);
        setVehicleToDelete(null);
    };

    const confirmDelete = async () => {
        if (!permissions.can_write) {
            alert('Sie haben keine Berechtigung, Fahrzeuge zu löschen.');
            return;
        }

        if (vehicleToDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/fuhrpark/${vehicleToDelete.id}`, {
                    headers: getAuthHeaders()
                });
                setFuhrparkData((prevData) =>
                    prevData.filter((vehicle) => vehicle.id !== vehicleToDelete.id)
                );
                handleCloseDeleteConfirm();
            } catch (error) {
                console.error('Fehler beim Löschen des Eintrags:', error);
            }
        }
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction:
                prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const sortedAndFilteredData = useMemo(() => {
        const sortedData = [...fuhrparkData].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        return sortedData.filter((vehicle) => {
            return (
                (!filterValues.funkcode ||
                    vehicle.funkcode.toLowerCase().includes(filterValues.funkcode.toLowerCase())) &&
                (!filterValues.kennzeichen ||
                    vehicle.kennzeichen.toLowerCase().includes(filterValues.kennzeichen.toLowerCase())) &&
                (!filterValues.marke ||
                    vehicle.marke.toLowerCase().includes(filterValues.marke.toLowerCase())) &&
                (!filterValues.modell ||
                    vehicle.modell.toLowerCase().includes(filterValues.modell.toLowerCase())) &&
                (filterValues.allrad === null || vehicle.allrad === filterValues.allrad) &&
                (filterValues.aktiv === null || vehicle.aktiv === filterValues.aktiv) &&
                (!searchTerm ||
                    vehicle.kennzeichen.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    vehicle.funkcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    vehicle.marke.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    vehicle.modell.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        });
    }, [fuhrparkData, sortConfig, filterValues, searchTerm]);

    const handleFilterClick = (event, key) => {
        setFilterAnchorEl({ ...filterAnchorEl, [key]: event.currentTarget });
    };

    const handleFilterClose = (key) => {
        setFilterAnchorEl({ ...filterAnchorEl, [key]: null });
    };

    const isFilterOpen = (key) => Boolean(filterAnchorEl[key]);

    const columns = [
        { id: 'id', label: 'ID', sortable: true, filterable: false },
        { id: 'funkcode', label: 'Funkcode', sortable: true, filterable: true },
        { id: 'kennzeichen', label: 'Kennzeichen', sortable: true, filterable: true },
        { id: 'marke', label: 'Marke', sortable: true, filterable: true },
        { id: 'modell', label: 'Modell', sortable: true, filterable: true },
        { id: 'allrad', label: 'Allrad', sortable: true, filterable: true, isBoolean: true },
        { id: 'aktiv', label: 'Aktiv', sortable: true, filterable: true, isBoolean: true },
    ];

    return (
        <div className={`main-container ${darkMode ? 'dark' : ''}`}>
            <h1>Fuhrpark</h1>

            <Grid2 container spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                <Grid2 item xs={12} sm={8} md={8}>
                    <TextField
                        label="Suche"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Grid2>
                <Grid2 item xs={12} sm={4} md={4} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
                    {permissions.can_write && (
                        <IconButton onClick={handleOpenModal}>
                            <AddIcon />
                        </IconButton>
                    )}
                </Grid2>
            </Grid2>

            {/* Modal für Hinzufügen/Bearbeiten */}
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>{isEditing ? 'Fahrzeug bearbeiten' : 'Neues Fahrzeug hinzufügen'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Funkcode"
                        fullWidth
                        value={newVehicle.funkcode}
                        onChange={(e) => setNewVehicle({ ...newVehicle, funkcode: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Kennzeichen"
                        fullWidth
                        value={newVehicle.kennzeichen}
                        onChange={(e) => setNewVehicle({ ...newVehicle, kennzeichen: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Marke"
                        fullWidth
                        value={newVehicle.marke}
                        onChange={(e) => setNewVehicle({ ...newVehicle, marke: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Modell"
                        fullWidth
                        value={newVehicle.modell}
                        onChange={(e) => setNewVehicle({ ...newVehicle, modell: e.target.value })}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newVehicle.allrad}
                                onChange={(e) => setNewVehicle({ ...newVehicle, allrad: e.target.checked })}
                            />
                        }
                        label="Allrad"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newVehicle.aktiv}
                                onChange={(e) => setNewVehicle({ ...newVehicle, aktiv: e.target.checked })}
                            />
                        }
                        label="Aktiv"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={addOrEditVehicle} color="primary">
                        {isEditing ? 'Speichern' : 'Hinzufügen'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog zum Bestätigen des Löschens */}
            <Dialog open={openDeleteConfirm} onClose={handleCloseDeleteConfirm}>
                <DialogTitle>Löschen bestätigen</DialogTitle>
                <DialogContent>Möchten Sie dieses Fahrzeug wirklich löschen?</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteConfirm} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={confirmDelete} color="primary">
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Tabelle */}
            <TableContainer component={Paper} sx={{ backgroundColor: darkMode ? 'transparent' : '#ffffff' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow className="table-row-small">
                            {columns.map((column) => (
                                <TableCell key={column.id}>
                                    {column.sortable ? (
                                        <TableSortLabel
                                            active={sortConfig.key === column.id}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort(column.id)}
                                            sx={{ color: darkMode ? '#ffffff' : '#000000' }}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        column.label
                                    )}
                                    {column.filterable && (
                                        <>
                                            <IconButton onClick={(event) => handleFilterClick(event, column.id)}>
                                                <FilterListIcon />
                                            </IconButton>
                                            <Popover
                                                open={isFilterOpen(column.id)}
                                                anchorEl={filterAnchorEl[column.id]}
                                                onClose={() => handleFilterClose(column.id)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                {column.isBoolean ? (
                                                    <div style={{ padding: '10px' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={filterValues[column.id] === true}
                                                                    onChange={() =>
                                                                        setFilterValues({
                                                                            ...filterValues,
                                                                            [column.id]:
                                                                                filterValues[column.id] === true ? null : true,
                                                                        })
                                                                    }
                                                                />
                                                            }
                                                            label="Ja"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={filterValues[column.id] === false}
                                                                    onChange={() =>
                                                                        setFilterValues({
                                                                            ...filterValues,
                                                                            [column.id]:
                                                                                filterValues[column.id] === false ? null : false,
                                                                        })
                                                                    }
                                                                />
                                                            }
                                                            label="Nein"
                                                        />
                                                    </div>
                                                ) : (
                                                    <TextField
                                                        label={`${column.label} filtern`}
                                                        variant="outlined"
                                                        value={filterValues[column.id]}
                                                        onChange={(e) =>
                                                            setFilterValues({
                                                                ...filterValues,
                                                                [column.id]: e.target.value,
                                                            })
                                                        }
                                                    />
                                                )}
                                            </Popover>
                                        </>
                                    )}
                                </TableCell>
                            ))}
                            {permissions.can_write && (
                                <TableCell>Aktionen</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedAndFilteredData.length > 0 ? (
                            sortedAndFilteredData.map((vehicle) => (
                                <TableRow key={vehicle.id} className="table-row-small">
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>
                                            {column.isBoolean
                                                ? vehicle[column.id] ? 'Ja' : 'Nein'
                                                : vehicle[column.id]}
                                        </TableCell>
                                    ))}
                                    {permissions.can_write && (
                                        <TableCell>
                                            <IconButton onClick={() => handleEdit(vehicle)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleOpenDeleteConfirm(vehicle)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length + 1} align="center">
                                    Keine Daten verfügbar
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Fuhrpark;
