// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BaseLayout from './components/BaseLayout';
import Dashboard from './pages/Dashboard';
import Fuhrpark from './pages/Fuhrpark';
import Kilometerliste from './pages/Kilometerliste';
import LoginForm from './components/LoginForm'; // Login-Komponente
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from './context/authContext';
import ProtectedRoute from './components/ProtectedRoute'; // Für geschützte Routen
import News from './pages/News';
import Dateien from './pages/Dateien'; // Sicherstellen, dass der Pfad korrekt ist
import Kalender from './pages/Kalender';
import RegisterForm from './components/RegisterForm';
import Profil from './pages/Profil';
import Rechteverwaltung from './pages/Rechteverwaltung';
import { PermissionsProvider } from './context/PermissionsContext'; // Importiere den PermissionsProvider
import Chat from './pages/chat';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  // Theme basierend auf Dark Mode Zustand
  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const updateThemeColor = (isDarkMode) => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', isDarkMode ? '#152331' : '#ffffff');
    }
  };

  useEffect(() => {
    updateThemeColor(darkMode);
  }, [darkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            {/* Login-Seite */}
            <Route
              path="/login"
              element={
                <LoginForm darkMode={darkMode} setDarkMode={setDarkMode} />
              }
            />
            <Route path="/register" element={<ProtectedRoute><RegisterForm /></ProtectedRoute>} />

            {/* Geschützte Routen */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                    <Dashboard darkMode={darkMode} />
                  </BaseLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/fuhrpark"
              element={
                <ProtectedRoute>
                  <PermissionsProvider page="fuhrpark">
                    <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                      <Fuhrpark darkMode={darkMode} />
                    </BaseLayout>
                  </PermissionsProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/kilometerliste"
              element={
                <ProtectedRoute>
                  <PermissionsProvider page="kilometerliste">
                    <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                      <Kilometerliste darkMode={darkMode} />
                    </BaseLayout>
                  </PermissionsProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/news"
              element={
                <ProtectedRoute>
                  <PermissionsProvider page="news">
                    <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                      <News darkMode={darkMode} />
                    </BaseLayout>
                  </PermissionsProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/dateien"
              element={
                <ProtectedRoute>
                  <PermissionsProvider page="dateien">
                    <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                      <Dateien darkMode={darkMode} />
                    </BaseLayout>
                  </PermissionsProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/kalender"
              element={
                <ProtectedRoute>
                  <PermissionsProvider page="kalender">
                    <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                      <Kalender darkMode={darkMode} />
                    </BaseLayout>
                  </PermissionsProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/profil"
              element={
                <ProtectedRoute>
                  <PermissionsProvider page="profil">
                    <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                      <Profil darkMode={darkMode} setDarkMode={setDarkMode} />
                    </BaseLayout>
                  </PermissionsProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/rechteverwaltung"
              element={
                <ProtectedRoute>
                  <PermissionsProvider page="rechteverwaltung">
                    <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                      <Rechteverwaltung />
                    </BaseLayout>
                  </PermissionsProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute>
                  <PermissionsProvider page="chat">
                    <BaseLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                      <Chat darkMode={darkMode} />
                    </BaseLayout>
                  </PermissionsProvider>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
