import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Grid, Card, CardContent, Typography,
    CardActions, Button, TextField, Breadcrumbs, Link,
    Tooltip, Menu, MenuItem, IconButton
} from '@mui/material';
import {
    Delete as DeleteIcon,
    Download as DownloadIcon,
    Folder as FolderIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import '../assets/styles/Dateien.css';
import '../assets/styles/Main.css';

const StyledCard = styled(Card)(({ theme }) => ({
    minWidth: 150,
    margin: theme.spacing(1),
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
}));

const Dateien = ({ darkMode }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [folderName, setFolderName] = useState('');
    const [currentPath, setCurrentPath] = useState('');
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [editMode, setEditMode] = useState(null);
    const [editFolderName, setEditFolderName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [contextItem, setContextItem] = useState(null);
    const theme = useTheme();

    const fetchFolderContents = async (path = '') => {
        try {
            const response = await axios.get(`/api/folders?path=${path}`);
            setFolders(response.data.folders);
            setFiles(response.data.files);
            setCurrentPath(path);
        } catch (error) {
            console.error('Fehler beim Abrufen der Inhalte:', error);
        }
    };

    useEffect(() => {
        fetchFolderContents();
    }, []);

    // Datei auswählen
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    // Datei hochladen
    const handleFileUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('folder', currentPath);

        try {
            await axios.post('/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Datei erfolgreich hochgeladen!');
            setSelectedFile(null);
            fetchFolderContents(currentPath);
        } catch (error) {
            console.error('Fehler beim Hochladen:', error);
        }
    };

    // Datei löschen
    const handleFileDelete = async (fileName) => {
        try {
            await axios.delete(`/api/delete-file`, {
                data: { filePath: `${currentPath}/${fileName}` },
            });
            alert('Datei erfolgreich gelöscht!');
            fetchFolderContents(currentPath);
        } catch (error) {
            console.error('Fehler beim Löschen der Datei:', error);
        }
    };

    // Datei herunterladen
    const handleFileDownload = async (fileName) => {
        try {
            const response = await axios.get('/api/download-file', {
                params: {
                    filePath: `${currentPath}/${fileName}`
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Fehler beim Herunterladen der Datei:', error);
        }
    };

    // Ordner erstellen
    const handleCreateFolder = async () => {
        if (!folderName.trim()) return;

        try {
            const response = await axios.post('/api/create-folder', { folder: `${currentPath}/${folderName}` });
            alert('Ordner erfolgreich erstellt!');
            setFolderName('');
            fetchFolderContents(currentPath);
        } catch (error) {
            console.error('Fehler beim Erstellen des Ordners:', error);
        }
    };

    // Ordner bearbeiten (Umbenennen)
    const handleFolderEdit = (folder) => {
        setEditMode(folder);
        setEditFolderName(folder);
    };

    // Ordner speichern (Umbenennen)
    const handleSaveFolder = async (folder) => {
        try {
            await axios.put('/api/edit-folder', {
                oldFolderPath: `${currentPath}/${folder}`,
                newFolderName: editFolderName
            });
            alert('Ordner erfolgreich umbenannt!');
            setEditMode(null);
            fetchFolderContents(currentPath);
        } catch (error) {
            console.error('Fehler beim Bearbeiten des Ordners:', error);
        }
    };

    // Ordner löschen
    const handleFolderDelete = async (folder) => {
        try {
            await axios.delete(`/api/delete-folder`, { data: { folderPath: `${currentPath}/${folder}` } });
            alert('Ordner erfolgreich gelöscht!');
            fetchFolderContents(currentPath);
        } catch (error) {
            console.error('Fehler beim Löschen des Ordners:', error);
        }
    };

    // In einen Ordner wechseln
    const handleFolderClick = (folder) => {
        fetchFolderContents(`${currentPath}/${folder}`);
    };

    // Zurück zum vorherigen Ordner navigieren
    const handleBackClick = () => {
        const parentPath = currentPath.split('/').slice(0, -1).join('/');
        fetchFolderContents(parentPath || '');
    };

    // Breadcrumb-Navigation generieren
    const generateBreadcrumbs = () => {
        const pathSegments = currentPath.split('/').filter(seg => seg);
        const breadcrumbs = [
            <Link key="home" color="inherit" onClick={() => fetchFolderContents('')} style={{ cursor: 'pointer' }}>
                Home
            </Link>,
        ];

        pathSegments.forEach((segment, index) => {
            const path = pathSegments.slice(0, index + 1).join('/');
            breadcrumbs.push(
                <Link key={path} color="inherit" onClick={() => fetchFolderContents(path)} style={{ cursor: 'pointer' }}>
                    {segment}
                </Link>
            );
        });

        return breadcrumbs;
    };

    // Suchfilter anwenden
    const filteredFolders = folders.filter(folder => folder.toLowerCase().includes(searchTerm.toLowerCase()));
    const filteredFiles = files.filter(file => file.toLowerCase().includes(searchTerm.toLowerCase()));

    // Drag-and-Drop mit react-dropzone
    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setSelectedFile(acceptedFiles[0]);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    // Kontextmenü öffnen
    const handleContextMenu = (event, item, type) => {
        event.preventDefault();
        setContextItem({ item, type });
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setContextItem(null);
    };

    // Kontextmenü Aktionen
    const handleContextDownload = () => {
        if (contextItem && contextItem.type === 'file') {
            handleFileDownload(contextItem.item);
        }
        handleCloseMenu();
    };

    const handleContextDelete = () => {
        if (contextItem) {
            if (contextItem.type === 'file') {
                handleFileDelete(contextItem.item);
            } else if (contextItem.type === 'folder') {
                handleFolderDelete(contextItem.item);
            }
        }
        handleCloseMenu();
    };

    return (
        <div className={`main-container ${darkMode ? 'dark' : ''}`}>
            <h1>Dateien-Explorer</h1>

            {/* Breadcrumbs */}
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '16px' }}>
                {generateBreadcrumbs()}
            </Breadcrumbs>

            {/* Toolbar */}
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                <div className="explorer-toolbar" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Button onClick={handleBackClick} disabled={!currentPath} variant="contained" sx={{ marginRight: '10px' }}>
                        Zurück
                    </Button>

                    <div className="create-folder" style={{ display: 'inline-flex', alignItems: 'center', marginRight: '20px' }}>
                        <TextField
                            label="Neuer Ordnername"
                            variant="outlined"
                            size="small"
                            value={folderName}
                            onChange={(e) => setFolderName(e.target.value)}
                            sx={{ marginRight: '10px' }}
                        />
                        <Button onClick={handleCreateFolder} variant="contained" color="primary">
                            Erstellen
                        </Button>
                    </div>

                    <div className="upload-file" style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                            <input {...getInputProps()} />
                            <Tooltip title="Dateien hierher ziehen oder klicken">
                                <Button variant="contained" component="span" startIcon={<FolderIcon />}>
                                    Datei auswählen
                                </Button>
                            </Tooltip>
                        </div>
                        <Button onClick={handleFileUpload} disabled={!selectedFile} variant="contained" color="success" sx={{ marginLeft: '10px' }}>
                            Hochladen
                        </Button>
                    </div>
                </div>
            </Grid>

            {/* Suchleiste */}
            <TextField
                label="Suchen"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ marginBottom: '16px' }}
                fullWidth
            />

            {/* Dateien und Ordner anzeigen */}
            <Grid container spacing={2}>
                {/* Ordner anzeigen */}
                {Array.isArray(filteredFolders) && filteredFolders.map((folder) => (
                    <Grid item xs={12} sm={6} md={3} lg={2} key={folder}>
                        <StyledCard onContextMenu={(e) => handleContextMenu(e, folder, 'folder')}>
                            <CardContent onDoubleClick={() => handleFolderClick(folder)}>
                                <FolderIcon fontSize="large" color="action" />
                                {editMode === folder ? (
                                    <TextField
                                        value={editFolderName}
                                        onChange={(e) => setEditFolderName(e.target.value)}
                                        size="small"
                                        fullWidth
                                    />
                                ) : (
                                    <Typography variant="h6" component="div">
                                        {folder}
                                    </Typography>
                                )}
                            </CardContent>
                            <CardActions>
                                {editMode === folder ? (
                                    <Button
                                        onClick={() => handleSaveFolder(folder)}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        color="primary"
                                    >
                                        Speichern
                                    </Button>
                                ) : (
                                    <IconButton onClick={() => handleFolderEdit(folder)} color="primary">
                                        <EditIcon />
                                    </IconButton>
                                )}
                                <IconButton onClick={() => handleFolderDelete(folder)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </CardActions>
                        </StyledCard>
                    </Grid>
                ))}

                {/* Dateien anzeigen */}
                {Array.isArray(filteredFiles) && filteredFiles.map((file) => (
                    <Grid item xs={12} sm={6} md={3} lg={2} key={file}>
                        <StyledCard onContextMenu={(e) => handleContextMenu(e, file, 'file')}>
                            <CardContent>
                                <FolderIcon fontSize="large" color="action" /> {/* Ersetze durch ein Datei-Icon, z.B. InsertDriveFileIcon */}
                                <Typography variant="h6" component="div">
                                    {file}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Tooltip title="Herunterladen">
                                    <IconButton onClick={() => handleFileDownload(file)} color="primary">
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Löschen">
                                    <IconButton onClick={() => handleFileDelete(file)} color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </CardActions>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>

            {/* Kontextmenü */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {contextItem && contextItem.type === 'file' && (
                    <>
                        <MenuItem onClick={handleContextDownload}>
                            <DownloadIcon fontSize="small" style={{ marginRight: '8px' }} /> Herunterladen
                        </MenuItem>
                        <MenuItem onClick={handleContextDelete}>
                            <DeleteIcon fontSize="small" style={{ marginRight: '8px' }} /> Löschen
                        </MenuItem>
                    </>
                )}
                {contextItem && contextItem.type === 'folder' && (
                    <>
                        <MenuItem onClick={() => handleFolderEdit(contextItem.item)}>
                            <EditIcon fontSize="small" style={{ marginRight: '8px' }} /> Bearbeiten
                        </MenuItem>
                        <MenuItem onClick={handleContextDelete}>
                            <DeleteIcon fontSize="small" style={{ marginRight: '8px' }} /> Löschen
                        </MenuItem>
                    </>
                )}
            </Menu>
        </div>
    );
};

export default Dateien;
